.app {
  width: 100%;
}
.topbar,
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar {
  height: 65px;
  margin-top: 0;
  background-color: #13345d;
  padding: 1em 0;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
}
.topbar-contents,
.nav-contents,
.menu-side-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-contacts-item {
  display: flex;
  align-items: center;
}
.top-contacts-item.mid-item {
  margin: 0 8px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0 8px;
}
.top-contacts-item img {
  width: 20px;
  margin-right: 4px;
}
.top-contacts-item span {
  font-size: 14px;
  color: #000;
}
.top-social a:hover {
  background-color: #0224ff;
}
.top-social a.mid-item {
  margin: 0 1.5em;
}
.top-social a img {
  width: 20px;
}
.brand-side {
  display: block;
  text-decoration: none;
  font-size: 1.5em;
  color: #87a7d9;
}

.nav-label,
.hide-menu-btn,
#toggle-responsive-menu {
  display: none;
}
.menu-side .main-menu {
  display: flex;
  cursor: pointer;
}
.menu-side .main-menu > li {
  margin-right: 4em;
}
.menu-side ul.main-menu > li > a {
  text-decoration: none;
  color: #eee;
  font-family: "Agrandir Heavy", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}
.menu-side ul.main-menu > li > a:hover,
.menu-side ul.main-menu > li > a:focus,
.dropdown ul li > a:hover {
  color: #87a7d9;
  text-decoration: underline;
}
.nav-cta {
  background-color: #87a7d9;
  color: #13345d;
  width: auto;
  font-size: 12px;
}

.dropdown {
  position: relative;
}

.dropdown ul.dropdown-menu {
  display: none;
  flex-flow: column;
  border: 1px solid #77d4fd;
  border-bottom: 0;
  box-shadow: 0 0 3px 1px rgba(41, 69, 31, 0.3);
  position: absolute;
  top: 100%;
  width: 130px;
  height: auto;
  background-color: #fff;
  z-index: 10;
}

.dropdown ul li {
  padding: 10px;
  border-bottom: 1px solid #77d4fd;
}
.dropdown ul li > a {
  text-decoration: none;
  color: #77d4fd;
}

.dropdown:hover ul.dropdown-menu {
  display: flex;
  animation: animatezoom 1s;
}
.hide-responsive {
  display: none;
}
.hero {
  background-color: #fff;
  width: 100%;
  position: relative;
  overflow: hidden;
  top: 65px;
}

.hero-content-container {
  position: absolute;
  top: 30%;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.curtain {
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}
.curtain1 {
  background-image: url("./assets/images/content/h7.jpeg");
}
.curtain2 {
  background-image: url("./assets/images/content/jm2.jpg");
}
.curtain3 {
  background-image: url("./assets/images/content/jm7.jpg");
}
.curtain4 {
  background-image: url("./assets/images/content/h4.jpg");
}
.curtain5 {
  background-image: url("./assets/images/content/h6.jpeg");
}
.curtain:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.curtain p {
  position: absolute;
  z-index: 5;
  top: 90%;
  left: 2.5%;
  color: #ccc;
  font-size: 16px;
}
.best-bars-container {
  align-items: center;
  justify-content: center;
}

.best-star {
  width: 20px;
}
.best-bar {
  width: 150px;
  height: 3px;
  background-color: #fff;
}
.brand-section h2 {
  font-size: 5em;
  color: #fff;
}

.sub-cta {
  border: 2px solid #fff;
}
.main-cta {
  background-color: #87a7d9;
}
.cta-section {
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;
}

.cta-section .button {
  width: 250px;
  padding: 16px;
  padding-bottom: 10px;
}

.best-text {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  color: #fff;
}

.slider-items-container {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  z-index: 2;
}
.sliderImg {
  width: 100%;
}

.slider-items-container .slick-arrow {
  background-color: transparent !important;
  border-radius: 1px;
  height: 30px;
  padding-top: 3px;
  width: 40px;
  top: 100%;
}
.slider-items-container .slick-arrow {
  background-color: #13345d !important;
}
.slider-items-container .slick-arrow:hover {
  background-color: #555 !important;
}

.slider-items-container .slick-next,
.slider-items-container .slick-prev {
  z-index: 100 !important;
  display: none !important;
}
.slick-dots {
  bottom: 0 !important;
}
.carousel .slider-items-container.center-slide-arrows .slick-arrow {
  top: 50% !important;
}
.carousel .slider-items-container .slick-next {
  right: 30px;
}

.carousel .slider-items-container .slick-prev {
  left: 30px;
}

.page-hero .slider-items-container.center-slide-arrows .slick-arrow {
  top: 30% !important;
}
.slick-dots li {
  margin-bottom: 25px !important;
}
.slick-dots li button:before {
  color: #87a7d9 !important;
}
.ctas-container {
  justify-content: space-around;
  align-items: center;
}

.ctas-container .button {
  width: auto;
  text-transform: uppercase;
}

.subcta {
  background-color: #fff;
  color: #77d4fd;
}
.buttons-container {
  display: flex;
}
.strength-pull {
  margin-top: 65px;
}
.strengths-cards-container {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.strength-card {
  border: 1px solid #ccc;
  border-bottom: 4px solid #87a7d9;
  width: 300px;
  padding: 10px;
  margin: 1em 0;
}
.strength-card:hover {
  background-color: #cddcf9;
  cursor: pointer;
}
.strength-card span {
  color: #555;
  text-transform: uppercase;
  font-size: 12px;
}

.strength-card h3 {
  color: #13345d;
  margin: 1em 0;
  font-size: 1.2em;
  text-transform: capitalize;
}

.footer {
  background-image: url("./../src/assets/images/content/pipe.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.footer:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
}

.footer .container {
  position: relative;
  z-index: 10;
}

.footer-menu-sections-container,
.footer-about {
  display: flex;
  justify-content: space-between;
}

.footer-menu-section h3 {
  text-transform: capitalize;
  margin-bottom: 1em;
  color: #87a7d9;
}

.footer-menu-section ul li {
  margin: 1em 0;
}
.footer-menu-section ul li a {
  text-decoration: none;
  text-transform: capitalize;
  color: #eee;
}
.footer-menu-section ul li a:hover {
  text-decoration: underline;
  color: #87a7d9;
}

.footer-about {
  border-top: 1px solid #87a7d9;
  padding-top: 3em;
  margin-top: 3em;
  color: #eee;
}
.footer-brand {
  align-items: center;
}
.footer-brand img {
  width: 100px;
  height: auto;
  margin-right: 50px;
}

.footer-brand p,
.footer-copyright p {
  margin: 1em 0;
}
.footer-copyright p.flex {
  justify-content: right;
}
.footer-copyright p a img {
  width: 30px;
}
.footer-copyright p a {
  margin-right: 2em;
}
.footer-copyright p a:last-child {
  margin-right: 0;
}

.footer-copyright p {
  color: #eee;
}
.textpage-inner {
  justify-content: space-between;
  align-items: flex-start;
}
.textpage-text-side {
  width: 60%;
}

.textpage-text-side h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.textpage-text-side h3 {
  font-size: 1.1em;
  margin: 1em 0;
  margin-bottom: 8px;
}

.textpage-text-side p {
  margin-bottom: 1em;
  font-size: 1.1em;
}

.mission-side {
  width: 30%;
  background-color: #13345d;
  color: #eee;
  padding: 0 1em;
}
.mission-item {
  border-top: 1px solid #fed507;
  display: flex;
  padding: 2em 8px;
}
.mission-item img {
  width: 40px;
}
.mission-item p {
  margin-top: 1em;
}
.mission-item h2 {
  font-size: 1.5em;
}

.textpage-text-model {
  border-left: 10px solid #87a7d9;
  background-color: #13345d;
  padding: 1em;
}
.textpage-text-model p {
  margin-bottom: 0;
  color: #fff;
}
.results {
  background-color: #fed507;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/content/p1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.results-title {
  font-size: 1em;
  text-align: center;
}
.about-results-inner {
  justify-content: space-around;
  margin-top: 2em;
}
.about-results-item {
  background-color: transparent;
  color: #fff;
  padding: 1em;
  border-radius: 2px;
  text-align: center;
  width: 25%;
  border-right: 4px solid #fed507;
}
.about-results-item h5 {
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #77d4fd;
}

.about-contents {
  justify-content: space-between;
  align-items: center;
}
.about-text-side {
  width: 45%;
}

.images-side {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-around;
}
.images-side img {
  flex: 0 0 auto;
  width: 45%;
  margin-bottom: 5%;
}

.images-side img.about-big-img {
  width: 100%;
}

.section-head h2.section-title {
  font-size: 3em;
  text-transform: uppercase;
  margin: 0.5em 0;
}
.section-head p {
  font-size: 1.5em;
  color: #000;
}

.about-text-side .section-head p {
  font-size: 1.2em;
  margin: 1em 0;
}
.about-img {
  border-radius: 2px;
  height: 400px;
  object-fit: cover;
}
.big-img {
  width: auto;
}
.small-img {
  width: 30%;
  margin-bottom: 100px;
}
.section-name {
  text-transform: uppercase;
}
.section-body p {
  font-size: 1.2em;
  margin: 1em 0 0 0;
}
.section-body ul.list-body-items li {
  margin: 10px 0;
  font-size: 1.2em;
  color: #000;
  display: flex;
  align-items: center;
}
.section-body ul.list-body-items li img {
  width: 20px;
  margin-right: 5px;
}
.section-body ul.list-body-items li span {
  margin-top: 5px;
}
.aligned-content-container {
  justify-content: space-around;
}
.spacing {
  width: 200px;
}
.aligned-item img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: contain;
}
.aligned-item h3 {
  color: #fff;
  font-size: 1.5em;
}

.aligned-item p {
  font-size: 1.2em;
  margin-top: 5px;
  color: #eee;
}
.aligned-item {
  align-items: center;
  padding-bottom: 3em;
  width: 350px;
}
.right-aligned-content .aligned-item img {
  order: 1;
  margin-left: 8px;
}
.left-aligned-content .aligned-item img {
  margin-right: 8px;
}
.right-aligned-content .aligned-item.pushed {
  margin-left: -3em;
}
.left-aligned-content .aligned-item.pushed {
  margin-left: 3em;
}

.right-aligned-content .aligned-item {
  text-align: right;
}
.about-snippet {
  margin: 1em 0;
  font-size: 3em;
}
.floating-image {
  position: absolute;
  width: 400px;
  height: 200px;
  left: 60px;
  top: 90%;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
}
.initiative-card {
  width: 100%;
  padding: 1em;
  margin-bottom: 5em;
}
.initiative-card .card-body {
  justify-content: space-between;
  align-items: center;
}
.initiative-card .card-body-text {
  width: 50%;
}
.initiative-card .card-image-side {
  width: 45%;
  background-color: #fed507;
}

.ordered.initiative-card .card-image-side {
  order: -1;
}

.ordered.initiative-card .card-body-text {
  text-align: right;
}

.services-cards-container,
.pdts-cards-container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}
.service-card {
  width: 45%;
  text-align: justify;
  margin-bottom: 4em;
  border: 2px solid #0224ff;
  border-top: 0;
  border-bottom: 0;
  padding: 8px;
  border-radius: 25%;
}
.service-card:last-child {
  margin-bottom: 2em;
}

.service-card h3 {
  color: #0224ff;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 2px solid #0224ff;
  text-transform: uppercase;
}
.service-card div {
  padding: 2em;
}

.service-card div p {
  margin-top: 10px;
}

.pdt-card > div {
  margin-bottom: 1em;
}
.pdt-card > div:last-child {
  margin-bottom: 0;
}
.slider-items-container.pdt-img-container {
  padding: 0 !important;
}
.service-card-image img,
.pdt-img img {
  height: 200px;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
}
.pdt-img-container,
.pdt-img img {
  height: 150px;
}
.card-cta,
.pdt-cta {
  background-color: transparent;
  color: #77d4fd;
  text-align: center;
  display: block;
  cursor: pointer;
  text-decoration: underline;
}
.pdt-cta .card-cta {
  width: 100%;
}

.pdt-card {
  width: 300px;
  text-align: center;
  background-color: #eee;
  border-radius: 2px;
  padding: 4px;
  margin-bottom: 1.2em;
}
.pdt-img {
  position: relative;
}
.pname-span {
  position: absolute;
  bottom: 0;
  top: 87%;
  right: 0;
  left: 0;
  text-align: center;
  color: #fed507;
}
.initiative-card .card-image-side {
  width: auto;
  height: auto;
}
.contacts-container {
  justify-content: space-between;
}
.contact-item,
.contact-item-body {
  text-align: center;
}
.contact-item-head {
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}
.contact-item-head img {
  margin-right: 4px;
  width: 25px;
}
.contact-item-head h2 {
  font-size: 1em;
}
.map-container iframe {
  width: 100%;
  height: 300px;
}

.gallery-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.gallery-container figure {
  width: 30%;
  margin: 1em;
  border: 2px solid rgba(41, 69, 31, 0.3);
  padding: 10px;
  border-radius: 2px;
  background-color: lightyellow;
}

.gallery-container figure figcaption {
  font-size: 0.7em;
}

.farm-tour-video-container {
  width: 100%;
}

.team-container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}
.team-container li {
  margin: 30px;
  width: 300px;
  padding: 1em;
}
.team-container li figure {
  background-color: #77d4fd;
  border-radius: 50%;
}
.team-container li figure img {
  border-radius: 8px;
}
.team-container li figure figcaption {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  color: #fed507;
  background-color: #77d4fd;
  padding: 1em 0;
}
.floating-button-container {
  position: fixed;
  right: 30px;
  bottom: 5%;
  z-index: 100;
  text-align: right;
  overflow: hidden;
  width: 50px;
  height: 50px;
}
.whatsapp-button {
  background-color: #25d366;
  padding: 0;
  box-shadow: 16px 2px 2px 16px rgba(64, 29, 186, 0.5);
}

.whatsapp-button a {
  color: #fff !important;
  display: flex !important;
  align-items: center;
}
.whatsapp-button a img {
  line-height: 0;
  width: 50px;
  height: 50px;
}
.breeds-container {
  justify-content: space-around;
  flex-flow: row wrap;
}
.breed-card {
  width: 30%;
  padding: 8px;
  border: 8px solid #77d4fd;
  border-radius: 2px;
  background-color: #fed507;
  margin: 1em;
}
.breed-card h3 {
  margin: 1em 0;
}
.breed-card h4 {
  margin-bottom: 4px;
}
.breed-card p {
  font-size: 1em;
  color: #000;
  text-align: justify;
}
.breed-card img {
  height: 250px;
  object-fit: cover;
  object-position: center;
}
.breed-card div {
  margin: 1em 0;
}
.why-container {
  justify-content: center;
}
.why-image-side,
.why-text-side {
  width: 50%;
}
.why-image-side img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.why-text-side {
  padding: 1em;
}
.why-list-container {
  width: 100%;
  margin: auto;
}
.why-list-container li {
  display: flex;
  margin-left: 1em;
  margin-bottom: 2em;
}
.why-list-container li:last-child {
  margin-bottom: 0;
}
.why-list-container li img {
  width: 20px;
  margin-right: 10px;
}

.why-list-container li h3 {
  margin-bottom: 5px;
  font-size: 1em;
  color: #000;
}
.why-list-container li p {
  margin-top: 0;
}

.gallery-slider-container {
  height: 215px;
  padding-top: 3px;
}
.slider-container ul.slider-items-wrap {
  display: flex;
  align-items: center;
  padding: 0 0.5em;
}
.slider-container ul > li {
  text-align: center;
  list-style: none;
  cursor: pointer;
}

.slide .slide-image-container {
  border: 2px solid #77d4fd;
  border-right: 0;
  padding: 8px;
  background-color: #fed507;
}
.partners-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: end;
}
.partner-card {
  width: auto;
  max-width: 150px;
  margin: 20px;
}
.partner-card figcaption {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
}

.reso-container {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: flex-end;
}
.reso-card {
  width: 280px;
  margin: 20px;
}
.reso-card div {
  padding: 8px;
  border: 1px solid #fed507;
  border-top: 0;
  border-bottom: 0;
}
.reso-card h3 {
  text-transform: uppercase;
  padding: 10px;
  background-color: #77d4fd;
  color: #fff;
  text-align: center;
}
.reso-card p {
  margin: 10px 0;
  text-align: justify;
  font-size: 1em;
}
.reso-card a.button {
  background-color: #fed507;
  color: #77d4fd;
  width: 100%;
}

.fees-tables-container table {
  width: 100%;
}
.fees-table-item {
  margin: 40px 0;
}
.fees-table-item h3 {
  margin-bottom: 10px;
}

.fees-table,
.fees-table th,
.fees-table td {
  padding: 8px;
  border: 1px solid #000;
}
.fees-table th {
  color: #fed507;
  text-transform: uppercase;
}

.tution-table th {
  background-color: #77d4fd;
}

.hightlights-container {
  justify-content: space-between;
  flex-flow: row wrap;
}
.highlight-card {
  width: 30%;
  position: relative;
}
.highlight-card img {
  object-fit: cover;
}

.highlight-card a {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}

.highlight-card:after {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  transition: 0.3s ease;
}

.highlight-card:hover:after {
  background-color: rgba(0, 0, 0, 0.2);
}

.highlight-card p {
  position: absolute;
  left: 10px;
  bottom: 20px;
  z-index: 2;
  color: #fff;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 2em;
  text-transform: uppercase;
}

.highlight-card a:hover h3 {
  color: #77d4fd;
}

.title {
  text-align: center;
  padding: 0 1em;
}

.highlights-title {
  text-align: center;
  font-size: 1em;
  padding: 0 1em;
}
.highlight-content-container {
  padding: 0 10px;
  color: #000;
}
.highlight-content-container h2 {
  margin-bottom: 1em;
  color: #000;
  font-size: 1.5em;
}
.highlight-content-container img {
  width: 100%;
}
.highlight-content-container figure {
  margin-bottom: 1em;
}
.highlight-content-container figure figcaption {
  font-size: 1em;
  text-align: center;
}

.highlight-content-container div p {
  margin-bottom: 10px;
}

.highlight-content-container div h3 {
  margin-top: 1em;
  margin-bottom: 10px;
}

.projects .highlight-card p {
  text-transform: capitalize;
  font-size: 1.2em;
  width: 60%;
}

.clients-container,
.projects-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.client-card {
  padding: 8px;
  border: 1px solid #87a7d9;
  margin: 5px;
  background-color: #77d4fd;
}
.client-card p {
  margin: 0;
  padding: 12px 0;
  text-transform: uppercase;
  font-size: 1em;
}
.projects-container {
  justify-content: space-around;
}

.project-card {
  width: 30%;
  border: 1px solid #eee;
  border-radius: 2px;
  background-color: #fff;
  margin: 1em 0;
}
.project-card figure {
  line-height: normal;
  padding: 8px;
}
.project-card img {
  border-radius: 2px;
}
.project-card figcaption {
  text-align: center;
  font-size: 12px;
}

.policies-container{
  justify-content: center;
}

.policy-card {
  width: 280px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 2px;
}

.policy-card p{
  margin: 1em 0;
}

.policy-card .button{
  
  background-color: #13345d;
  color: #eee;
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .buttons-container {
    justify-content: center;
  }
  .service-card {
    width: 40%;
  }
  .gallery-container figure {
    min-width: 40%;
    max-width: 40%;
  }
  .menu-side .main-menu > li {
    margin-right: 2em;
  }

  .top-social a img {
    width: 30px;
  }
  .initiative-card .card-image-side {
    display: none;
  }
  .initiative-card .card-body-text {
    width: 100%;
  }

  .strengths-cards-container {
    width: 100%;
    justify-content: center;
  }

  .strength-card {
    width: 420px;
    margin: 1em;
  }
  .about-text-side {
    width: 50%;
    margin: auto;
    text-align: center;
  }
  .about-contents {
    flex-flow: column;
  }
  .about-text-side {
    order: -1;
    width: 700px;
    margin-bottom: 2em;
  }
  .images-side {
    width: 700px;
  }
  .hightlights-container {
    justify-content: space-around;
  }
  .highlight-card {
    width: auto;
    flex: 1 1 45%;
    margin: 1em;
    height: 300px;
  }
  .highlight-card h3 {
    font-size: 2.5em;
  }
  .section-head h2.section-title {
    font-size: 2.5em;
  }
  .section-head p {
    font-size: 1.2em;
  }
  .spacing {
    width: 50px;
  }
  .aligned-item {
    width: 300px;
  }
  .about-footer {
    width: 300px;
  }
}
@media screen and (min-width: 800px) and (max-width: 959px) {
  .dog-bg {
    background-size: 200px;
  }

  .width-960 {
    width: 100%;
  }

  .textpage-text-side {
    width: 65%;
  }
  .mission-side {
    padding: 0 0.1em;
  }

  .buttons-container {
    justify-content: center;
  }

  .menu-side .main-menu > li {
    margin-right: 1em;
  }

  .top-social a img {
    width: 30px;
  }
  .initiative-card .card-image-side {
    display: none;
  }
  .initiative-card .card-body-text {
    width: 100%;
  }

  .strengths-cards-container {
    width: 100%;
    justify-content: center;
  }

  .strength-card {
    width: 350px;
    margin: 1em;
  }
  .about-text-side {
    width: 50%;
    margin: auto;
    text-align: center;
  }
  .about-contents {
    flex-flow: column;
  }
  .about-text-side {
    order: -1;
    width: 700px;
    margin-bottom: 2em;
  }
  .images-side {
    width: 700px;
  }
  .hightlights-container {
    justify-content: space-around;
  }
  .highlight-card {
    width: auto;
    flex: 1 1 45%;
    margin: 1em;
    height: 300px;
  }
  .highlight-card h3 {
    font-size: 2.5em;
  }
  .section-head h2.section-title {
    font-size: 2.5em;
  }
  .section-head p {
    font-size: 1.2em;
  }

  .navbar {
    padding: 10px 0;
  }
  .menu-side-inner {
    flex-flow: column;
    background-color: #fff;
    width: 150px;
    padding: 8px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
  }
  .main-menu {
    display: flex;
    flex-flow: column;
  }
  .menu-side .main-menu > li {
    margin: 0;
    border-top: 1px solid #77d4fd;
    padding: 1em 0;
  }
  .menu-side .main-menu > li:first-child {
    margin-top: 5px;
  }
  .menu-side .main-menu > li:last-child {
    border-bottom: 1px solid #77d4fd;
  }
  .menu-side ul.main-menu > li > a {
    color: #13345d;
  }

  .hide-responsive {
    display: block;
  }
  .nav-cta {
    margin-top: 1em;
    width: 100%;
  }
  .dropdown ul {
    right: 50%;
  }
  .nav-label {
    display: block;
    cursor: pointer;
  }
  .showParent {
    display: flex;
    animation: animatezoom 1s;
  }
  .hide-menu-btn {
    padding: 0;
    box-shadow: none;
    display: flex;
  }

  .footer-menu-section {
    margin-right: 10px;
  }
  .footer-menu-section:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 600px) and (max-width: 799px) {
  .buttons-container {
    justify-content: center;
  }
  .welcome .hero-content-inner {
    width: 90%;
    margin: auto;
  }
  .welcome h1 {
    font-size: 3em;
  }
  .highlight-card h3 {
    font-size: 3em;
  }
  .strengths-cards-container {
    width: 100%;
    justify-content: center;
  }

  .strength-card {
    width: 250px;
    margin: 1em;
  }
  .strength-card h3 {
    font-size: 1em;
  }
  .client-card p {
    font-size: 13px;
  }
  .client-card {
    flex: 1 1 50%;
  }
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }
  .menu-side .main-menu > li {
    margin-right: 1em;
  }
  .best-text {
    font-size: 13px;
  }
  .curtain p {
    font-size: 12px;
  }
  .navbar {
    padding: 10px 0;
  }
  .about-text-side {
    width: 50%;
    margin: auto;
    text-align: center;
  }
  .about-contents {
    flex-flow: column;
  }
  .about-text-side {
    order: -1;
    width: 550px;
    margin-bottom: 2em;
  }
  .images-side {
    width: 550px;
  }
  .hightlights-container {
    justify-content: space-around;
  }
  .highlight-card {
    width: auto;
    flex: 1 1 45%;
    margin: 1em;
    height: 300px;
  }

  .section-head h2.section-title {
    font-size: 1.5em;
  }
  .section-body ul.list-body-items li {
    font-size: 1em;
    color: #000;
    text-align: left;
  }
  .spacing {
    width: 10px;
  }

  .menu-side ul.main-menu > li > a {
    font-size: 10px;
  }
  .hero-body-head h1 {
    font-size: 4em;
  }
  .hero-content-inner {
    width: 300px;
  }
  .hero-body-head p {
    font-size: 1.3em;
  }
  .hero-content-container {
    top: 25%;
  }
  .dog-bg {
    background-size: 150px;
  }
  .section-head p {
    font-size: 1em;
  }
  .about-results-item h5 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  .about-results-item {
    width: 30%;
  }

  .width-960 {
    width: 100%;
  }
  .width-600 {
    max-width: 600px;
    width: 100%;
  }

  .textpage-text-side {
    width: 65%;
  }
  .mission-side {
    padding: 0 0.1em;
    width: 100%;
    margin: 2em 0;
  }

  .menu-side-inner {
    flex-flow: column;
    background-color: #fff;
    width: 150px;
    padding: 8px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
  }
  .main-menu {
    display: flex;
    flex-flow: column;
  }
  .menu-side .main-menu > li {
    margin: 0;
    border-top: 1px solid #77d4fd;
    padding: 1em 0;
  }
  .menu-side .main-menu > li:first-child {
    margin-top: 5px;
  }
  .menu-side .main-menu > li:last-child {
    border-bottom: 1px solid #77d4fd;
  }
  .menu-side ul.main-menu > li > a {
    color: #13345d;
  }
  .hide-responsive {
    display: block;
  }
  .nav-cta {
    margin-top: 1em;
    width: 100%;
  }
  .dropdown ul {
    right: 50%;
  }
  .nav-label {
    display: block;
    cursor: pointer;
  }
  .showParent {
    display: flex;
    animation: animatezoom 1s;
  }
  .hide-menu-btn {
    padding: 0;
    box-shadow: none;
    display: flex;
  }
  .pdt-card {
    width: 250px;
  }
  .textpage-inner,
  .contacts-container {
    flex-flow: column;
  }
  .textpage-text-side {
    width: 80%;
    margin: auto;
  }
  .contact-item {
    margin-bottom: 2em;
  }
  .title {
    font-size: 24px;
  }
  .width-800 {
    width: 100%;
  }
  .project-card {
    width: 45%;
  }

  .footer-brand img {
    width: 70px;
    height: auto;
    margin-right: 20px;
  }

  .footer-menu-section {
    margin-right: 16px;
  }
  .footer-menu-section:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 599px) {
  .container {
    padding: 0 0.3em;
  }
  .brand-side {
    font-size: 1em;
  }
  .brand-section h2 {
    font-size: 3em;
  }

  .buttons-container,
  .cta-section {
    justify-content: center;
    flex-wrap: wrap;
  }
  .cta-section .button {
    margin: 1em;
  }
  .best-bar {
    width: 125px;
  }

  .highlight-card h3 {
    font-size: 3em;
  }
  .strengths-cards-container {
    width: 100%;
    justify-content: center;
  }

  .strength-card {
    width: 90%;
    margin: 1em;
  }
  .strength-card h3 {
    font-size: 1em;
  }
  .client-card p {
    font-size: 13px;
  }
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }
  .menu-side .main-menu > li {
    margin-right: 1em;
  }
  .best-text {
    font-size: 13px;
  }
  .curtain p {
    font-size: 12px;
  }
  .navbar {
    padding: 10px 0;
  }

  .about-contents {
    flex-flow: column;
  }
  .about-text-side {
    order: -1;
    width: 90%;
    margin-bottom: 2em;
    text-align: center;
  }
  .images-side {
    width: 90%;
  }
  .hightlights-container {
    justify-content: space-around;
  }
  .highlight-card {
    width: auto;
    flex: 1 1 90%;
    margin: 1em;
    height: 300px;
  }
  .highlight-card p {
    font-size: 1.5em;
  }
  .client-card {
    flex: 1 1 50%;
  }
  .section-head h2.section-title {
    font-size: 1.5em;
  }
  .section-body ul.list-body-items li {
    font-size: 1em;
    color: #000;
    text-align: left;
  }
  .spacing {
    width: 10px;
  }

  .menu-side ul.main-menu > li > a {
    font-size: 10px;
  }
  .hero-body-head h1 {
    font-size: 4em;
  }
  .hero-content-inner {
    width: 300px;
  }
  .hero-body-head p {
    font-size: 1.3em;
  }
  .hero-content-container {
    top: 15%;
  }
  .floating-button-container {
    right: 15px;
  }
  .dog-bg {
    background-size: 150px;
  }
  .section-head p {
    font-size: 1em;
  }
  .about-results-item h5 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  .about-results-item {
    width: 30%;
  }

  .width-960 {
    width: 100%;
  }
  .width-600 {
    max-width: 600px;
    width: 100%;
  }

  .textpage-text-side {
    width: 65%;
  }
  .mission-side {
    padding: 0 0.1em;
    width: 100%;
    margin: 2em 0;
  }

  .menu-side-inner {
    flex-flow: column;
    background-color: #fff;
    width: 150px;
    padding: 8px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
  }
  .main-menu {
    display: flex;
    flex-flow: column;
  }
  .menu-side .main-menu > li {
    margin: 0;
    border-top: 1px solid #77d4fd;
    padding: 1em 0;
  }
  .menu-side .main-menu > li:first-child {
    margin-top: 5px;
  }
  .menu-side .main-menu > li:last-child {
    border-bottom: 1px solid #77d4fd;
  }
  .menu-side ul.main-menu > li > a {
    color: #13345d;
  }
  .hide-responsive {
    display: block;
  }
  .nav-cta {
    margin-top: 1em;
    width: 100%;
  }
  .dropdown ul {
    right: 50%;
  }
  .nav-label {
    display: block;
    cursor: pointer;
  }
  .showParent {
    display: flex;
    animation: animatezoom 1s;
  }
  .hide-menu-btn {
    padding: 0;
    box-shadow: none;
    display: flex;
  }
  .pdt-card {
    width: 250px;
  }
  .textpage-inner,
  .contacts-container {
    flex-flow: column;
  }
  .textpage-text-side {
    width: 90%;
    margin: auto;
  }
  .contact-item {
    margin-bottom: 2em;
  }
  .title {
    font-size: 24px;
  }
  .width-800 {
    width: 100%;
  }
  .project-card {
    width: 100%;
  }

  .footer-brand img {
    width: 70px;
    height: auto;
    margin-right: 0;
  }
  .footer-menu-sections-container,
  .footer-about,
  .footer-brand,
  .footer-copyright {
    justify-content: center;
    flex-flow: column;
  }
  .footer-menu-section {
    margin-bottom: 1em;
    margin-left: 1em;
  }
  .footer-menu-section:last-child {
    margin-right: 0;
  }
  .footer-brand {
    text-align: center;
  }
  .footer-copyright {
    display: flex;
    text-align: center;
  }
  .footer-copyright p.flex {
    justify-content: center;
  }
  .footer-copyright p {
    margin-bottom: 10px;
  }
  .slogan-section .best-text {
    font-size: 1em;
  }
}
